'use strict';

class LineChartController {

  constructor($scope) {
    this.$scope = $scope;
    var ctrl = this;
    var resize = function() {
      ctrl.sizeElements();
    };
    $scope.$watch('content', resize);
    $(window).resize(resize);
    $(window).scroll(resize);
    $('.line-chart').resize(resize);
  }

  sizeElements() {
    $('.line-chart').css('height',this.$scope.height+'px');

    $('.line-chart .axis-line.middle').attr('x1', this.$scope.margin).attr('y1', (this.$scope.height - this.$scope.margin)/2).attr('x2', this.width).attr('y2', (this.$scope.height - this.$scope.margin)/2);
    $('.line-chart .axis-line.bottom').attr('x1', this.$scope.margin).attr('y1', this.$scope.height - this.$scope.margin).attr('x2', this.width).attr('y2', this.$scope.height - this.$scope.margin);

    $('.line-chart .x-axis-label.bottom').attr('y', this.$scope.height - this.$scope.margin + 4);
    $('.line-chart .x-axis-label.middle').attr('y', (this.$scope.height-this.$scope.margin)/2 + 4);

    $('.line-chart polyline').attr('points', this.getPointsString());
  }

  width() {
    return $('.line-chart').width();
  }

  maxValue() {
    var max = 10;
    for(var i=0 ; i<this.$scope.content.length ; i++) {
      if(this.$scope.content[i].value > max) {
        max = this.$scope.content[i].value;
      }
    }
    return max;
  }

  halfValue() {
    return Math.floor(this.maxValue()/2);
  }

  getX(index) {
      if (!this.$scope.content || this.$scope.content.length === 0) {
        return 0;
      }
      return this.$scope.margin + index * (this.width()-this.$scope.margin) / this.$scope.content.length;
  }

  getY(index) {
    if (!this.$scope.content || this.$scope.content.length === 0) {
      return 0;
    }
    return (this.$scope.height-this.$scope.margin) - this.$scope.content[index].value * (this.$scope.height-this.$scope.margin) / this.maxValue();
  }

  getLabel(index) {
    return index%3===0 || index===this.$scope.content.length-1 ? this.$scope.content[index].title : '';
  }

  getPointsString() {
    var string = '';
    for(var i=0 ; i<this.$scope.content.length ; i++) {
      string += (this.getX(i) + 10).toString() + ',' + this.getY(i) + ' ';
    }
    return string;
  }

}

angular.module('cpformplastApp')
  .controller('LineChartController', LineChartController);
